import {
	Modal,
	ModalOverlay,
	ModalContent,
	ModalHeader,
	ModalBody,
	ModalCloseButton,
	Heading,
	Text,
	OrderedList,
	ListItem,
	chakra,
} from '@chakra-ui/react'

export default function TermsAndConditions({ isOpen, onClose }) {
	return (
		<>
			<Modal
				isOpen={isOpen}
				onClose={onClose}
				size='4xl'
				scrollBehavior='inside'
			>
				<ModalOverlay bg='rgba(0, 0, 0, 0.8);' />
				<ModalContent>
					<ModalHeader>TERMS OF SERVICES AND PRIVACY POLICY</ModalHeader>
					<ModalCloseButton />
					<ModalBody
						sx={{
							'&::-webkit-scrollbar': {
								width: '1em',
							},
							'&::-webkit-scrollbar-thumb': {
								backgroundColor: `#8C8E90`,
								borderRadius: '2rem',
								backgroundClip: 'padding-box',
								border: '4px solid rgba(0, 0, 0, 0)',
								outline: 'none',
							},
						}}
					>
						<Heading>Welcome to greneOS</Heading>
						<Text mb='1rem'>
							Please read this Terms of Services with care as this forms a
							contract between you and Grene Robotics (India) Private Limited
							(hereinafter called “greneOS”). Here the terms greneOS, We, us,
							our, have the same meaning as greneOS. “greneOS” is an Enterprise
							Virtual Assistant (“Software as a Service”). This “Terms of
							Services” governs your access and use of the productivity tools
							provided by greneOS which may involve sharing and storage of
							files, documents, material, data, audio, video, images, or other
							content (collectively called “Content”). A person/organisation
							accessing or using the Services of “greneOS” mobile application
							(“User”) is agreeing to register before using the services of this
							mobile application, and hereby, you are agreeing to the Terms of
							Services in full and shall be bound by its terms. If you do not
							agree to be bound by these terms, you must not use the services.
							The greneOS mobile application services are provided for free and
							without any warranty. greneOS has no liability to you for any loss
							suffered by you by registering and using the services provided by
							it. You must be eligible to enter into this Agreement and you are
							attained majority. The services provided by greneOS is not meant
							for minors.
						</Text>
						<OrderedList spacing='1rem'>
							<ListItem>
								<Text as='b'>General Disclaimer</Text>
								<Text>
									greneOS as a solution for all essential productivity tools,
									takes all reasonable care to provided uninterrupted and error
									less continuous services but without any warranty that access
									to the service be continuous and error free. We do not make
									any representation regarding the usefulness of our services.
									The reasonable care does not include without limitation, acts
									of God, acts of Government or Laws, floods, fires,
									earthquakes, civil unrest, acts of terror, Internet failures
									or delays, or denial of service attacks.
								</Text>
							</ListItem>
							<ListItem>
								<Text as='b'>Changes to Terms of Services</Text>
								<Text>
									greneOS reserve the right to amend the terms and conditions of
									this “Terms of Services” at any time and without notice. The
									act of use of the services continuously shall constitute your
									acceptance of the new terms and conditions. In case you don't
									agree to these new terms of the “Terms of Services”, then you
									must stop the use of the services.
								</Text>
							</ListItem>
							<ListItem>
								<Text as='b'>Access to the Services</Text>
								<Text>
									To obtain access to the Services, the greneOS application
									needs to be installed in mobile devices. The mobile device
									associated number must be provided by the user. This follows a
									verification process, wherein the verification code received
									as a SMS must be manually entered by the user. This completes
									the registration of the users for our services. The user must
									allow us to access the mobile phone address book and their
									geographical location.
								</Text>
							</ListItem>
							<ListItem>
								<Text as='b'>Consent to Electronic Communications</Text>
								<Text>
									By registering with greneOS, you agree and understand that we
									may send some notices and updates on your use of our Services
									through SMS , emails, messages to User's greneOS account, or
									through other means available through Services.
								</Text>
							</ListItem>
							<ListItem>
								<Text as='b'>Content</Text>
								<Text>
									greneOS as a solution allows you to store contents in your
									accounts for unlimited period of time in its cloud
									infrastructure. For the content in your account, we don't
									claim any ownership, have any control, make any verification
									or endorse the Content. You shall be solely responsible for
									the nature, quality and accuracy of the Content and greneOS
									will have no liability of any kind as a result of the deletion
									of, correction of, destruction of, damage to, loss of or
									failure to store or encrypt any Content.
								</Text>
							</ListItem>
							<ListItem>
								<Text as='b'>Acceptable Use</Text>
								<Text>
									You must not use the Service to threaten, harass another
									person, organization, and engage in any unlawful or fraudulent
									activity. You must not store or transmit inappropriate Content
									containing unlawful, defamatory, threatening, pornographic,
									abusive or otherwise objectionable material of any kind or
									nature that encourages conduct that could constitute a
									criminal offense.
								</Text>
							</ListItem>
							<ListItem>
								<Text as='b'>Termination of Use of the Service</Text>
								<Text>
									greneOS reserves the sole discretion and right to suspend or
									terminate your access to the Service, both permanently and
									temporarily at any time without giving any notice or cause,
									without incurring liability of any kind. If you are found to
									be involved in spamming or similar activities to other users
									while using the application, greneOS reserves all rights to
									suspend or terminate your access to the Service. Sometimes,
									the suspension of services may be temporary in nature due to
									scheduled downtime and recurring downtime, or unplanned
									technical problems and outages.
								</Text>
							</ListItem>
							<ListItem>
								<Text as='b'>Third Party Content</Text>
								<Text>
									The Services may contain some features and functionalities
									that link you to third party Web sites, directories, servers,
									networks, systems and the internet as a whole. The Services
									may contain some features and functionalities that give you
									the access to third party content, such as information and
									databases, applications, software, programs, products or
									services, etc. You are acknowledging here that we shall not be
									responsible for such content or services in any manner or
									circumstances. We are not responsible for transactions of any
									nature or kind between you and any third party. If you are
									posting content that you do not own rights to and this content
									is made accessible to other users, we reserve all the right to
									remove that content if it is reported and/or found that it
									infringes IPRs/copyright of others. We are not responsible for
									any such infringement or postings.
								</Text>
							</ListItem>
							<ListItem>
								<Text as='b'>Disclaimer of Warranty</Text>
								<Text>
									YOU EXPRESSLY UNDERSTAND AND AGREE THAT YOUR USE OF OUR
									SERVICES IS AT YOUR SOLE RESPONSIBILITY OF RISK. THE SERVICE
									IS PROVIDED ON A “AS IS”, “WITH FAULTS” AND “AS AVAILABLE”
									BASIS. greneOS MOBILE APPLICATION AND AFFILIATES EXPRESSLY
									OVERRIDE EXCLUDE AND DISCLAIM ALL WARRANTIES OF ANY KIND,
									WHETHER EXPRESS OR IMPLIED, STATUTORY OR CUSTOMARY, OR
									OTHERWISE, INCLUDING BUT WITHOUT LIMITATION TO ANY WARRANTY
									THAT THE CONTENT WILL BE FULLY SECURE AND WILL NOT LOST OR
									DAMAGED, THAT THE SERVICES WILL BE CONTINUOUS OR ERROR FREE,
									OR ANY WARRANTY OF SUPERIOR QUALITY OR FITNESS FOR PARTICULAR
									PURPOSE, OR MERCHANTABILITY OR NON- INFRINGEMENT, AND ANY
									WARRANTY ARISING OUT OF PERFORMANCE AND TRADE USAGE.
								</Text>
							</ListItem>
							<ListItem>
								<Text as='b'>Limited Liability</Text>
								<Text>
									IN NO EVENT SHALL greneOS, ITS DIRECTORS, EMPLOYEES, OR
									AGENTS, BE LIABLE TO YOU FOR ANY DIRECT, INDIRECT, INCIDENTAL,
									SPECIAL, PUNITIVE, OR CONSEQUENTIAL DAMAGES WHATSOEVER
									RESULTING FROM ANY INTERRUPTION OF SERVICES, ERRORS, MISTAKES,
									INACCURACIES OF CONTENT, ANY UNAUTHORIZED ACCESS TO OR USE OF
									YOUR CONTENT, ANY BUGS, VIRUSES, TROJAN HORSES, OR MALICIOUS
									CODE, WHICH MAY BE TRANSMITTED TO OR THROUGH OUR SERVICE BY
									ANY THIRD PARTY, FOR ANY LOSS OR DAMAGE OF ANY KIND INCURRED
									AS A RESULT OF YOUR USE OF ANY CONTENT, WHETHER BASED ON
									WARRANTY, CONTRACT, TORT, OR ANY OTHER LEGAL THEORY, THE
									DISCLOSURE OF INFORMATION PURSUANT TO THESE TERMS OF SERVICE
									AND PRIVACY POLICY. THE FOREGOING LIMITATION OF LIABILITY
									SHALL APPLY TO THE EXTENT PERMITTED BY LAW.
								</Text>
							</ListItem>
							<ListItem>
								<Text as='b'>Governing Law</Text>
								<Text>
									The terms and conditions of this “Terms of Services” are
									governed by laws of Republic of India, particularly for
									interpretation of terms, claims for its breach and damages.
									For all disputes that may arise out of this contractual
									relationship, the parties consenting that the jurisdiction
									shall that of any civil court at Hyderabad, India.
								</Text>
							</ListItem>
							<ListItem>
								<Text as='b'>Intellectual Property</Text>
								<Text>
									The services, the interfaces, logos, software, images,
									designs, colour combinations, graphics, illustrations,
									patents, trademarks, service marks, copyrights, photographs,
									audio, videos and music and all the Intellectual Property
									Rights related thereto whether registered or not, are the
									exclusive intellectual property of greneOS. greneOS has no
									intellectual property rights in User Content.
								</Text>
							</ListItem>
							<ListItem>
								<Text as='b'>Entire Agreement and Severability</Text>
								<Text>
									This is the entire agreement between greneOS and You and is
									enforceable as a whole. The terms of this “Terms of Services”
									are applicable as permitted by law, in case any court of
									appropriate jurisdiction holds that apply to the maximum
									extent permitted by relevant law. If a court holds that any
									term either wholly or partly not enforceable or invalid, the
									rest of the terms shall be severable and remain in effect.
									Both parties shall replace such terms with new terms to the
									extent enforceable by law.
								</Text>
							</ListItem>
							<ListItem>
								<Text as='b'>Assignment and transfer</Text>
								<Text>
									This agreement is not transferable for you through assignment
									or otherwise. However, we may assign, transfer, or otherwise
									dispose our rights and obligations under this contract, in
									whole or in part, at any time without notice.
								</Text>
							</ListItem>
							<ListItem>
								<Text as='b'>Privacy Policy</Text>
								<Text>
									This Privacy Policy governs all the information that we
									collect or use and you share while accessing our services by
									using our mobile application. This privacy policy explains how
									information is collected, stored and used by greneOS and you
									as a user. For us, the privacy right of users is most
									important and we recognise and respect your right to privacy.
									Virtual workplace, greneOS collects user's details, email,
									phone number, device identification number, device
									information, device configuration, peripherals, etc. This
									information is required and needed for normal functioning of
									the application. greneOS stores the user contents in its
									public clouds.
								</Text>
								<chakra.ol type='a' pl='1rem'>
									<ListItem mt='1rem'>
										<Text as='b'>Confidentiality</Text>
										<Text>
											greneOS shall take all reasonable care to secure user
											content and user information as shared by the user during
											accessing the services. Here the term, "Confidential
											Information" means all confidential information disclosed
											by a party that is designated as “Confidential”.
										</Text>
									</ListItem>
									<ListItem mt='1rem'>
										<Text as='b'>Security</Text>
										<Text>
											greneOS takes reasonable measures to help protect user
											information. However it must be understood that no mobile
											application is absolutely secure. The user content is
											stored in cloud with reasonable security measures that
											protects user's content from loss, theft, misuse and
											unauthorized access, disclosure, alteration and
											destruction. The user information are stored and accessed
											with proper control and restriction with reasonable
											measures for guarding personal information collected by
											greneOS from you. However, we provide no guarantee that
											the information protection measures are impenetrable.
										</Text>
									</ListItem>
									<ListItem mt='1rem'>
										<Text as='b'>Collaboration and Sharing Features</Text>
										<Text>
											greneOS is a productivity tool which allows the team of a
											user work together on a task, projects, folders. It helps
											user to manage tasks & projects. The collaborative
											features of greneOS application allow you to share your
											Content through its Services. The use of such features
											enables the sharing of Content with people you want to
											collaborate with. For collaboration, your name, photo,
											phone number, location information from your profile and
											online account and your Content will be shared with your
											team members, and they may communicate and modify your
											content by changing, removing and adding Contents
											connection with your use of the collaboration features of
											greneOS. greneOS shall not in any manner be liable for
											loss of content or loss of privacy of users during
											collaboration activities.
										</Text>
									</ListItem>
									<ListItem mt='1rem'>
										<Text as='b'>User and Device Information</Text>
										<Text>
											The information collected directly from user are: name,
											username, email address, picture, postal address, phone
											number, information about data storage preferences,
											organisation details/employer's name, job title and
											contact number. The information collected when user
											accessed our service using the mobile device includes:
											hardware model, operating system and version, unique
											device identifiers, mobile network information and
											geographical location( at the point of transaction).
										</Text>
									</ListItem>
									<ListItem mt='1rem'>
										<Text as='b'>Use of Information</Text>
										<Text>
											The user information is primarily used to provide,
											operate, maintain and improve the basic Services.
											Secondarily, this information is used for sending
											technical notices, updates, security alerts and support,
											for monitor and analyze of trends, usage, and activities.
											We try our best to provide you an exceptional service. If
											you have a question, or you find anything wrong or
											missing, please send an email with the details to{' '}
											<a href='mailto:support@grenerobotics.com'>
												support@grenerobotics.com
											</a>
										</Text>
									</ListItem>
								</chakra.ol>
							</ListItem>
						</OrderedList>
					</ModalBody>
				</ModalContent>
			</Modal>
		</>
	)
}
