/* eslint-disable default-case */
/* eslint-disable react-hooks/exhaustive-deps */
import { Flex, Icon, IconButton, Image, Tooltip } from '@chakra-ui/react'
import { NavLink, useHistory, useLocation } from 'react-router-dom'
import GreneLogo from '../../assets/images/grene-logo.png'
import { SidebarOptions } from './sidebar.options'
import React, { useState, useEffect } from 'react'
import { useAuthContext } from '../../context/auth.context'
import { sharedConst } from '../../utils/action.constant'
import { useSharedContext } from '../../context/shared.context'
import { useLanguageContext } from '../../context/locale.context'
import { IoMdMail } from 'react-icons/io'

export default function Sidebar(props) {
	let history = useHistory()
	const location = useLocation()
	const {
		state: { authData },
	} = useAuthContext()
	const {
		state: { locale },
	} = useLanguageContext()
	const { dispatch } = useSharedContext()
	const [activePath, setactivePath] = useState(null)

	useEffect(() => {
		if (location.pathname === '/export' || location.pathname === '/report') {
			if (authData.asset_flag_export === 0) {
				history.push('/files')
				dispatch({
					type: sharedConst.SET_ACTION_PANEL_VIEW,
					view: 'QUEUE_SETUP_VIEW',
				})
				dispatch({
					type: sharedConst.CLEAR_ACTIVITY_SELECTION,
				})
			}
		}
	}, [authData])

	const loadSideBarData = (menuItem, index) => {
		const loginmode = sessionStorage.getItem("__gr__l_login_mode_otp")
		
		if(loginmode!=='3'&&menuItem.name!=='Azure Send Mail'){
		return (
			<NavLink
				isActive={(match, location) => {
					if (!match) {
						return false
					}
					if (match.url === menuItem.pathname) {
						setactivePath(menuItem.pathname)

            return true
          }
          return false
        }}
        to={menuItem}
        key={index}
      >
        <Tooltip
          label={locale[menuItem.name]}
          fontSize="sm"
          bg="secondary"
          color="black.700"
          key={menuItem.name}
        >
          <IconButton
            my={2}
            icon={<Icon as={menuItem.icon} h={6} w={6} />}
            isActive={activePath === menuItem.pathname}
            color={localStorage.getItem('color')}
            _focus={{
              color: localStorage.getItem('color'),
            }}
            _hover={{
              color: 'white',
              bg: localStorage.getItem('color'),
            }}
            _active={{
              color: 'white',
              bg: localStorage.getItem('color'),
            }}
            onClick={() => {
              if (menuItem.name.toLowerCase() === 'home') {
                dispatch({
                  type: sharedConst.SET_ACTION_PANEL_VIEW,
                  view: 'QUEUE_SETUP_VIEW',
                })
                dispatch({
                  type: sharedConst.CLEAR_ACTIVITY_SELECTION,
                })
              }
            }}
          />
        </Tooltip>
      </NavLink>
    )
		}
		else if(loginmode==='3'){
			return (
				<NavLink
					isActive={(match, location) => {
						if (!match) {
							return false
						}
						if (match.url === menuItem.pathname) {
							setactivePath(menuItem.pathname)
	
				return true
			  }
			  return false
			}}
			to={menuItem}
			key={index}
		  >
			<Tooltip
			  label={locale[menuItem.name]}
			  fontSize="sm"
			  bg="secondary"
			  color="black.700"
			  key={menuItem.name}
			>
			  <IconButton
				my={2}
				icon={<Icon as={menuItem.icon} h={6} w={6} />}
				isActive={activePath === menuItem.pathname}
				color={localStorage.getItem('color')}
				_focus={{
				  color: localStorage.getItem('color'),
				}}
				_hover={{
				  color: 'white',
				  bg: localStorage.getItem('color'),
				}}
				_active={{
				  color: 'white',
				  bg: localStorage.getItem('color'),
				}}
				onClick={() => {
				  if (menuItem.name.toLowerCase() === 'home') {
					dispatch({
					  type: sharedConst.SET_ACTION_PANEL_VIEW,
					  view: 'QUEUE_SETUP_VIEW',
					})
					dispatch({
					  type: sharedConst.CLEAR_ACTIVITY_SELECTION,
					})
				  }
				}}
			  />
			</Tooltip>
		  </NavLink>
		)
			}

  }

	const loadSideBar = (menuItem, index) => {
		if (menuItem.name === 'Gantt Chart') {
			if (authData?.asset_type_flag_enable_gantt_chart === 1) {
				return loadSideBarData(menuItem, index)
			}
		} else if (menuItem.name === 'Realtime Dashboard') {
			if (authData?.asset_type_flag_enable_dashboard === 1) {
				return loadSideBarData(menuItem, index)
			}
		} else if (menuItem.name === 'Document Repository') {
			if (authData?.organization_flag_document_repository_enabled === 1) {
				return loadSideBarData(menuItem, index)
			}
		} else {
			return loadSideBarData(menuItem, index)
		}
	}

	// TODO: Create hook to load the configuration for the organization.
	return (
		<Flex
			maxW='70px'
			minW='70px'
			height='100%'
			bg='white'
			alignItems='center'
			pt={2}
			mx={1}
			flexDir='column'
		>
			<Image
				my={3}
				boxSize='50px'
				objectFit='contain'
				alt='company logo'
				fallbackSrc={GreneLogo}
				src='https://stagingweb.greneos.com/static/media/logo.336da18f.png'
			/>
			{SidebarOptions.map((menuItem, index) => {
				if (authData) {
					//TODO: fix this with more concrete way
					let export_access = authData.asset_flag_export
					if (export_access === 0) {
					}
					switch (export_access) {
						case 0:
							if (['Export', 'Report'].includes(menuItem.name)) {
								return null
							}
							break
						case 1:
							if (['Report'].includes(menuItem.name)) {
								return null
							}
							break
						case 2:
							if (['Export'].includes(menuItem.name)) {
								return null
							}
							break
					}
					if (
						(export_access === 1 || export_access === 0) &&
						menuItem.name === 'Report'
					) {
						return null
					} else if (
						(export_access === 2 || export_access === 0) &&
						menuItem.name === 'Export'
					) {
						return null
					}
				}

        if (!!menuItem.isButton) {
          return (
            <Tooltip
              label={locale[menuItem.name]}
              fontSize="sm"
              bg="secondary"
              color="black.700"
              key={menuItem.name}
            >
              <IconButton
                my={2}
                icon={<Icon as={menuItem.icon} h={6} w={6} />}
                isActive={activePath === menuItem.pathname}
                color={localStorage.getItem('color')}
                _focus={{
                  color: localStorage.getItem('color'),
                }}
                _hover={{
                  color: 'white',
                  bg: localStorage.getItem('color'),
                }}
                _active={{
                  color: 'white',
                  bg: localStorage.getItem('color'),
                }}
                onClick={() => {
                  if (['files', 'home'].includes(menuItem.name.toLowerCase())) {
                    dispatch({
                      type: sharedConst.CLEAR_ACTIVITY_SELECTION,
                    })
                  }
                }}
              />
            </Tooltip>
          )
        }
        return loadSideBar(menuItem, index)
      })}
    </Flex>
  )
}
