import { BiCalendarExclamation } from 'react-icons/bi'
import { FiBarChart2 } from 'react-icons/fi'
import { ImStack } from 'react-icons/im'
import { BsClipboardData } from 'react-icons/bs'
import { RiGitRepositoryLine } from 'react-icons/ri'
import { HiChartPie } from 'react-icons/hi'
import { TiClipboard, TiFlowChildren } from 'react-icons/ti'
import { IoIosCalendar, IoMdMail } from 'react-icons/io'
import { MdFolderOpen } from 'react-icons/md'

export const SidebarOptions = [
	// {
	//   name: 'Home',
	//   icon: MdHome,
	//   isButton: true,
	//   pathname: '/home',
	// },
	{
		name: 'Home',
		icon: ImStack,
		pathname: '/files',
	},
	{
		name: 'Meetings',
		icon: IoIosCalendar,
		pathname: '/meetings',
	},
	{
		name: 'Realtime Dashboard',
		icon: FiBarChart2,
		pathname: '/dashboard',
	},
	{
		name: 'Export',
		icon: TiClipboard,
		pathname: '/export',
	},
	{
		name: 'Report',
		icon: BsClipboardData,
		pathname: '/report',
	},
	{
		name: 'Document Repository',
		icon: MdFolderOpen,
		pathname: '/documents',
	},
	{
		name: 'Gantt Chart',
		icon: TiFlowChildren,
		pathname: '/ganttChart',
	},
	{
		name: 'Azure Send Mail',
		icon: IoMdMail,
		pathname: '/azure-mail',
	},

	// {
	//   name: 'Chat',
	//   icon: IoIosChatbubbles,
	//   pathname: '/chat',
	// },
	// {
	//   name: 'Team',
	//   icon: MdPeople,
	//   pathname: '/team',
	// },
	// {
	//   name: 'Unavailability',
	//   icon: BiCalendarExclamation,
	//   pathname: '/leave',
	// },
	// {
	//   name: 'VIDM Repository',
	//   icon: RiGitRepositoryLine,
	//   pathname: '/vidm',
	// },
]
